angular.module('app.services', [])

  .factory('smoothScr', ['$window', ($window) ->
    scroll: (elementId, offset = 0) ->
      scrollPage = new SmoothScroll()
      scrollPage.animateScroll(document.querySelector(elementId), 0, {updateURL: false, header: '.header-placeholder', offset: offset, speed: 200, speedAsDuration: true})
  ])


  #####################################################################################
  ##
  ##  Bind actions on different XHR events that we have to trace
  ##

  .factory('XHREventsInterceptor', ['$q', '$filter', '$rootScope', 'UI', '$timeout', '$location', ($q, $filter, root, UI, $timeout, $location) ->

    response: (request) ->
      # Process notifications
    
      #switch request.config.method
      #  when 'GET'
      #    console.log "success GET"
      #  when 'PUT'
      #    console.log "success PUT"
      #  when 'POST'
      #    console.log "success POST"
      #  when 'DELETE'
      #    console.log "success DELETE"
      
      # Process reload on version mismatch
      version = request.headers 'X-Version'
      #if version? && version isnt window.app_version
      # There application has been updated. Need to reload the page
      #  UI.notify false, "Synced error. The page will be update in a 3 sec."
      #  $timeout () ->
      #    document.location.reload()
      #  , 3000

      # Return value
      request || $q.when request

    responseError: (request) ->
      switch request.status
        when 0
          # Request was aborted by us
          angular.noop()
        when 500, 400
          console.log "error 500,400: ", request.data.error
          if request.data.error == 'Timeout! Access denied'
            #angular.element('#inModal').modal('show')
            root.logout(true, 'responseError 1')
          #UI.notify false, request.data.error
        when 404
          UI.notify(false, request.data.error || 'Nicht gefunden!')
          return $location.path("/")
        when 401, 403, 503, 422
          console.log "error 401, 403, 503, 422: ", request.data.error
          if request.data.error == 'Timeout! Access denied'
            #angular.element('#inModal').modal('show')
            root.logout(true, 'responseError 2')
          # Logged out, refresh page to login
          #document.location.reload()
        else
          console.log "error responseError: ", request.data.error
          #UI.notify false
      $q.reject request

  ])

  .config(['$httpProvider', (h) -> h.interceptors.push('XHREventsInterceptor')])
  
  .factory('UI', ['$rootScope', (self) ->
    body = jQuery 'html, body'

    notify: (success, text = null) ->

      unless text
        text = if success then 'All right!' else 'Error!'

      jQuery.notify "#{text}",
        arrowShow: false
        autoHide: true
        position: 'top right'
        className: if success then 'success' else 'error'
        autoHideDelay: 3000

  ])
