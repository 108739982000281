angular.module('app.services', []).factory('smoothScr', [
  '$window',
  function($window) {
    return {
      scroll: function(elementId,
  offset = 0) {
        var scrollPage;
        scrollPage = new SmoothScroll();
        return scrollPage.animateScroll(document.querySelector(elementId),
  0,
  {
          updateURL: false,
          header: '.header-placeholder',
          offset: offset,
          speed: 200,
          speedAsDuration: true
        });
      }
    };
  }
//####################################################################################
//#
//#  Bind actions on different XHR events that we have to trace
//#
]).factory('XHREventsInterceptor', [
  '$q',
  '$filter',
  '$rootScope',
  'UI',
  '$timeout',
  '$location',
  function($q,
  $filter,
  root,
  UI,
  $timeout,
  $location) {
    return {
      response: function(request) {
        var version;
        // Process notifications

        //switch request.config.method
        //  when 'GET'
        //    console.log "success GET"
        //  when 'PUT'
        //    console.log "success PUT"
        //  when 'POST'
        //    console.log "success POST"
        //  when 'DELETE'
        //    console.log "success DELETE"

        // Process reload on version mismatch
        version = request.headers('X-Version');
        //if version? && version isnt window.app_version
        // There application has been updated. Need to reload the page
        //  UI.notify false, "Synced error. The page will be update in a 3 sec."
        //  $timeout () ->
        //    document.location.reload()
        //  , 3000

        // Return value
        return request || $q.when(request);
      },
      responseError: function(request) {
        switch (request.status) {
          case 0:
            // Request was aborted by us
            angular.noop();
            break;
          case 500:
          case 400:
            console.log("error 500,400: ",
  request.data.error);
            if (request.data.error === 'Timeout! Access denied') {
              //angular.element('#inModal').modal('show')
              root.logout(true,
  'responseError 1');
            }
            break;
          //UI.notify false, request.data.error
          case 404:
            UI.notify(false,
  request.data.error || 'Nicht gefunden!');
            return $location.path("/");
          case 401:
          case 403:
          case 503:
          case 422:
            console.log("error 401, 403, 503, 422: ",
  request.data.error);
            if (request.data.error === 'Timeout! Access denied') {
              //angular.element('#inModal').modal('show')
              root.logout(true,
  'responseError 2');
            }
            break;
          default:
            // Logged out, refresh page to login
            //document.location.reload()
            console.log("error responseError: ",
  request.data.error);
        }
        //UI.notify false
        return $q.reject(request);
      }
    };
  }
]).config([
  '$httpProvider',
  function(h) {
    return h.interceptors.push('XHREventsInterceptor');
  }
]).factory('UI', [
  '$rootScope',
  function(self) {
    var body;
    body = jQuery('html, body');
    return {
      notify: function(success,
  text = null) {
        if (!text) {
          text = success ? 'All right!' : 'Error!';
        }
        return jQuery.notify(`${text}`,
  {
          arrowShow: false,
          autoHide: true,
          position: 'top right',
          className: success ? 'success' : 'error',
          autoHideDelay: 3000
        });
      }
    };
  }
]);
